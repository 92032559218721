<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/smart.png" alt="">
			<div class="text">
				<p class="title">成功案列</p>
				<p class="subTitle">Successful cases</p>
			</div>
		</div>
		
		<div class="public-house">
			<div class="text">
				<div class="line"></div>
				<p class="title">承德公租房改造项目</p>
			</div>
			<img style="margin-bottom: 87px;" src="../../assets/imgs/success1.png" alt="">
			
			<div class="text">
				<div class="line"></div>
				<p class="title">承德围场满族蒙古族自治县疫情防控智慧中心</p>
			</div>
			
			<img src="../../assets/imgs/success2.png" alt="">
		</div>	
		
		<div style="background-color: rgb(244, 248, 255);text-align: center;">
			<img src="../../assets/imgs/success3.png" alt="">
		</div>
		<div style="text-align: center;">
			<img src="../../assets/imgs/success4.png" alt="">
		</div>
		<div style="background-color: rgb(244, 248, 255);text-align: center;">
			<img src="../../assets/imgs/success5.png" alt="">
		</div>
		
		<div class="hotel">
			<div class="text">
				<div class="line"></div>
				<p class="title">智慧家庭案例</p>
			</div>
			
			<img src="../../assets/imgs/success6.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}

.abstract .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
}

.abstract .text .title {
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.abstract .text .subTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 43px;
}

.title {
	height: 28px;
	font-size: 28px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 46px;
	margin-top: 4px;
}

.line {
	width: 54px;
	height: 8px;
	background: #245ECB;
}

.public-house {
	width: 1200px;
	margin: 48px auto 92px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.hotel {
	margin: 79px auto 92px;
	width: 1200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>